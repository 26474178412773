import { createRouter, createWebHistory } from 'vue-router'
import Home from '../components/Home.vue'
import Org_Mgmt from '../components/Org_Mgmt.vue'
import User_Mgmt from '../components/User_Mgmt.vue'
import User_Profile from '../components/User_Profile.vue'
import keycloak from '../KC_auth';
import AppConfig from '../../config.js';
import axios from 'axios';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta:{
      isAuthNeeded: false
    }
  },
  {
    path: '/Org_Mgmt',
    name: 'Org_Mgmt',
    component: Org_Mgmt,
    meta:{
      isAuthNeeded: true
    }
  },
  {
    path: '/User_Mgmt',
    name: 'User_Mgmt',
    component: User_Mgmt,
    meta:{
      isAuthNeeded: true
    }
  },
  {
    path: '/user_profile',
    name: 'user_profile',
    component: User_Profile,
    meta:{
      isAuthNeeded: true
    }
  },
  {
    path: '/logout',
    name: 'Logout',
    meta:{
      isAuthNeeded: true
    }
  },
  {
    path: '/login',
    name: 'Login',
    meta:{
      isAuthNeeded: true
    }
  },
  {
    path: '/refresh_token',
    name: 'refresh_token',
    meta:{
      isAuthNeeded: true
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),//i think it might be ui_base_url in env have to check it
  routes
})

router.beforeEach((to, from, next) => {
  // to and from are both route objects. must call `next`.
  if(to.meta.isAuthNeeded){
    keycloak.init({onLoad: 'login-required'}).then(auth => {
      if(auth){
        //add access token to loaclstorage
        localStorage.setItem('access-token', keycloak.token);
        let is_admin = false;
        localStorage.setItem('refresh-token', keycloak.refreshToken);
        localStorage.setItem('id-token', keycloak.idToken);
        let get_acc_token = ""
        let get_id_token = ""
        let get_refresh_token = ""
        //if(localStorage.getItem('access-token') != null){
        get_acc_token = keycloak.token
        get_id_token = keycloak.idToken
        get_refresh_token = keycloak.refreshToken
        //}
        let request_url = AppConfig.API_Base_URL + '/v1/me/admin-home'
        //console.log("Base URL", request_url)
        axios.get(request_url,{
          headers:{
              Authorization: 'Bearer ' + get_acc_token,
              "id-token": get_id_token,
              "access-token": get_acc_token,
              "refresh-token": get_refresh_token,
          }
        }).then(response => {
          console.log("response data in routes checking auth",response.data);
          /*if(response.headers["status_code"] === "200"){
              is_admin = true
          }
          else{
              is_admin = false
          }*/
          is_admin = true
          console.log("to path value is : ", to.path)
          if(to.path === '/logout'){
            //remove access-token from localstorage
            localStorage.removeItem('access-token');
            localStorage.removeItem('refresh-token');
            localStorage.removeItem('id-token');
            keycloak.logout({redirectUri: (AppConfig.UI_Base_URL + '/')});
          }
          else if(to.path === '/login'){
            window.location=(AppConfig.UI_Base_URL + '/');
          }
          else if(to.path === '/refresh_token'){
            keycloak.refreshToken;
            window.location=(AppConfig.UI_Base_URL + '/user_profile');
          }
          else if(!is_admin){//if not admin can only see home page and profile
            window.location=AppConfig.UI_Base_URL;
          }
          else{
            next();
          }
        }).catch(err => {
          console.log("error in admin-home in routes is ", err.response)
          is_admin = false
          console.log("to path value is : ", to.path)
          if(to.path === '/logout'){
            //remove access-token from localstorage
            localStorage.removeItem('access-token');
            localStorage.removeItem('refresh-token');
            localStorage.removeItem('id-token');
            keycloak.logout({redirectUri: (AppConfig.UI_Base_URL + '/')});
          }
          else if(to.path === '/login'){
            window.location=(AppConfig.UI_Base_URL + '/');
          }
          else if(to.path === '/refresh_token'){
            keycloak.refreshToken;
            window.location=(AppConfig.UI_Base_URL + '/user_profile');
          }
          else if(!is_admin){//if not admin can only see home page and profile
            window.location=AppConfig.UI_Base_URL;
          }
          else{
            next();
          }
        });
      }
      else{
        next(AppConfig.UI_Base_URL + '/');
      }
    //refresh token
    setInterval(() => {
      keycloak.updateToken(70).then((refreshed) => {
        if (refreshed) {
          localStorage.setItem('access-token', keycloak.token);
          localStorage.setItem('refresh-token', keycloak.refreshToken);
          localStorage.setItem('id-token', keycloak.idToken);
          console.log('Token refreshed' + refreshed);
        } else {
          console.log('Token not refreshed, valid for '
            + Math.round(keycloak.tokenParsed.exp + keycloak.timeSkew - new Date().getTime() / 1000) + ' seconds');
        }
      }).catch(() => {
        console.log('Failed to refresh token');
      });
    }, 6000)
    })
  }
  else{
    next();
  }
})

export default router