<template>
<div>
<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">
    <h1>Org Management</h1>
    <div class="container">
        <div class="box border-between" id="tree_view">
          <br>
            <TreeView 
              v-for="grp in Org_data"
              :key="grp"
              :node="grp"
              :color_node="color_node"
              @onClick="nodeWasClicked"
            /> <br>
            <button @click="refresh_org">Refresh Org</button>
        </div>
        <div class="box" style="text-align:left;" id="CRUD_options">
          <div v-if="!to_crud" style="color: #1c62b3;">
            <div 
            style="position: sticky; top: 0;
            color:black; background-color: white;
            font-size:30px"
            >{{ Pgroup_path }}</div>
            <h2>{{ line_of_work }}</h2>
            <h2>{{ First_Name }} {{ LastName }}</h2>
            <h2>{{ User_id }}</h2>
            <h2>{{ User_Name }}</h2>
            <br><br>
            <div style="color:black">To Remove this user Click Remove User Button</div><br>
            <button @click="remove_user(User_Name, User_id)">Remove User</button><br><br>
            <!--<div style="color:black;">Response: {{ resp_msg }}</div>-->
          </div>
          <div v-else>
            <div 
            style="position: sticky; top: 0; background-color: white;
            color: black;
            font-size: 30px"
            ><u>{{ Pgroup_path }}</u></div><br><br>
            <h2>Select the operation to perform on Group</h2>
            <div>
              <input type="radio" id="add_opr" name="crud_opr" @click="crud_fn">
              <label v-if="have_subgrps" for="add_opr">
                Add SubGroup
              </label>
              <label v-else for="add_opr">
                Add Member
              </label>  &nbsp; &nbsp;
              <input type="radio" id="remove_opr" name="crud_opr" @click="crud_fn">
              <label for="remove_opr">
                Remove Group
              </label><br>
            </div>
            <hr>
            <div v-if="show_opr_options" id="show_opr_options_div">
              {{opr_perform}} <br><br>
              <div v-if="opr_perform==='Remove Group Operation'">
                Click remove Group button to remove this group <!--: <b>{{ Pgroup_path }}</b> -->
                <br><br><button id="remove_grp" @click="remove_grp">Remove Group</button>
                <br><br>
              </div>
              <div v-if="opr_perform==='Add Operation'">
                <!-- In Group : {{ Pgroup_path }} <br><br> -->
                <div v-if="have_subgrps">
                  Add a SubGroup to this group <br><br>
                  <label for="grpName"><u>Name</u> : </label>
                  <input 
                    class="addGrpInputBox"
                    type="text" 
                    id ="grpName"
                    v-model="new_grp"
                    placeholder="Group name to be added"
                  /><br><br>
                  <br><br><button id="submit_add_grp" @click="add_new_grp">Add</button>
                </div>
                <div v-else>
                  <p v-if="!have_subgrps">you can only add users in this group, can't add subgroup to it</p>
                  Add a New Member in this group<br>
                  <div>
                    <h2>Existed User or new user</h2>
                    <div>
                      <input type="radio" id="user_exist" name="new_user_type" @click="check_new_user">
                      <label for="user_exist">
                        User Exist
                      </label> &nbsp; &nbsp;
                      <input type="radio" id="new_user_add" name="new_user_type" @click="check_new_user">
                      <label for="new_user_add">
                        New User
                      </label><br><br>
                    </div>
                  </div>
                  <div v-if="check_user_exist === 'not exist'">
                    <label for="email"><u>Email</u> : </label><br>
                    <input 
                      style="color:black; height:33px; fontSize:14pt; text-align: center;"
                      type="text" 
                      id ="email"
                      v-model="new_email"
                      placeholder="email"
                    /><br><br>
                    <label for="username"><u>UserName</u> : </label><br>
                    <input 
                      style="color:black; height:33px; fontSize:14pt; text-align: center;"
                      type="text" 
                      id ="username"
                      v-model="new_username"
                      placeholder="UserName"
                    /><br><br>
                    <label for="firstname"><u>FirstName</u> : </label><br>
                    <input 
                      style="color:black; height:33px; fontSize:14pt; text-align: center;"
                      type="text" 
                      id ="firstname"
                      v-model="new_firstname"
                      placeholder="FirstName"
                    /><br><br>
                    <label for="lastname"><u>LastName</u> : </label><br>
                    <input 
                      style="color:black; height:33px; fontSize:14pt; text-align: center;"
                      type="text" 
                      id ="lastname"
                      v-model="new_lastname"
                      placeholder="LastName"
                    /><br><br>
                    <label for="credentials"><u>Credentials</u> : </label><br>
                    <input 
                      style="color:black; height:33px; fontSize:14pt; text-align: center;"
                      type="password" 
                      id ="credentials"
                      v-model="new_credentials"
                      placeholder="Password"
                    />
                    <input type="checkbox" @click="Password_toggle">Show Password
                    <br>
                    <button style="height:30px;width:50px" @click="add_new_user">Add</button>
                  </div>
                  <div v-if="check_user_exist === 'exist'">
                    <label for="UserName"><u>UserName/Email</u> : </label>
                    <input 
                      style="color:black; height:33px; fontSize:14pt; text-align: center;"
                      type="text"
                      id ="UserName"
                      v-model="new_username"
                      v-on:input="Search_User"
                      placeholder="UserName/Email"
                    />
                    <button style="height:30px;width:50px" @click="add_existed_user">Add</button><br>
                    <div v-for="User in Searched_User_List" :key="User">
                      <span style="color:blue;font-weight: bold;cursor:pointer;" @click="new_username=User">{{User}}</span>
                    </div>
                  </div>
                </div>
              </div>
              <br><br>
            </div>
            <div id="api_rsp_msg">{{ resp_msg }}</div><br><br>
          </div>
        </div>
    </div>
</div>
</template>

<script src="./Org_Mgmt.js"></script>

<style scoped>
@import '../main.css';

* {
  box-sizing: border-box;
}

/* Create two equal columns that floats next to each other */
.column {
  float: left;
  width: 50%;
  padding: 20px;
  height: 117vh;
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}

/* For the border line between columns */
.border-between {
    border-right: 1px solid black;
}

.container {
  display: flex;
  flex-direction: row;
}

.box {
  width: 50%;
  padding: 20px;
}

.addGrpInputBox {
  color:black; 
  height:33px; 
  font-size:14pt; 
  text-align: center; 
  width:280px;
}

@media screen and (max-width: 992px){
  .container {
    flex-direction: column;
  }
  .box {
    width: 100%;
    padding: 20px;
  }
  .border-between {
    border-bottom: 1px solid black;
    border-right: 0px solid black;
  }
}
</style>