<template>
<div>
<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">
    <h1>User Management</h1>
    <div>
        <input
            type='text'
            v-model='user_search'
            placeholder="User Name or email"
            style="height:44px; fontSize:14pt; text-align: left; width:50%;"
        />
        <button 
        class="button" 
        type="button" 
        @Click="Search_User">Search</button>&nbsp;&nbsp;&nbsp;
        <div style="font-size:24px" class="popup fa" @click="myFunction()">&#xf059;
            <span class="popuptext" id="myPopup"><u>RegEx</u><br><br>
            . : Any character (except newline character)<br>
            * : Zero or more occurrences <br>
            + : One or more occurrences<br>
            ^ : Starts with<br>
            $ : Ends with<br>
            ? : Zero or one occurrences<br>
            | : Either or<br>
            Click <a href="https://docs.python.org/3/library/re.html" target="_blank">Here</a> for more
            </span>
        </div>
        <br><br>
    </div>
    <div class="container">
        <div class="box border" style="text-align: left;float: left;">
            <h1>Search Results</h1>
            <div style="white-space:pre-wrap;" v-if="query_limit_msg">
                {{ query_resp }}
            </div>
            <div v-else>
                <div style="font-size: 20px; cursor: pointer;" v-for="Users in visibleList" :key="Users">
                    <div :style="isClicked[Users] ? { 'color': 'red' } : { 'color': 'blue' }" @Click="Get_User_Details(Users)" ><u>{{Users}}</u></div><br>
                </div>
                <pagination
                    style="font-size: 20px; text-align: center; font-weight: bold;"
                    v-bind:Searched_User_List="Searched_User_List"
                    v-on:page:update="updatePage"
                    v-bind:currentPage="currentPage"
                    v-bind:pageSize="pageSize">
                </pagination>
            </div>
        </div>
        <div class="box" style="text-align: left;float: left;">
            <h1>User-Details</h1>
            <br>
            <div v-if="user_is_searched">
                <label for="email"><u>Email</u> : </label>
                <input 
                    style="color:gray; height:33px; fontSize:14pt; text-align: center; cursor: not-allowed;"
                    type="text" 
                    id = "email"
                    :value="user_details['email']" 
                    readonly 
                />
                <br><br>
                <label for="fullname"><u>FullName</u> : </label>
                <input 
                    style="color:gray; height:33px; fontSize:14pt; text-align: center; cursor: not-allowed;"
                    type="text" 
                    id ="fullname"
                    :value="user_details['fullname']" 
                    readonly 
                />
                <br><br>
                <u>User Enabled</u> :
                <label class="switch">
                    <input 
                    type="checkbox" 
                    id="user_enabled" 
                    @click="user_enabled_fn()"
                    >
                    <span class="slider round"></span>
                </label>
                <br><br>
                <u>Email Verified</u> :
                <label class="switch">
                    <input 
                    type="checkbox" 
                    id="email_verified" 
                    @click="email_verified_fn()"
                    >
                    <span class="slider round"></span>
                </label>
                <br><br>
                <label for="credentials"><u>Credentials</u> : </label>
                    <input 
                        style="color:black; height:33px; fontSize:14pt; text-align: center;"
                        type="password" 
                        id ="credentials"
                        v-model="user_details['password']"
                        placeholder="Password"
                    />
                    <input type="checkbox" @click="Password_toggle">Show Password
                <br><br>
                <button class="button" style="background-color: green; cursor: pointer;" @click="update_details"><b>SAVE</b></button>
                <br><br>
                <p>Response : {{ user_updated }}</p>
            </div>
        </div>
    </div>
</div>
</template>

<script src="./User_Mgmt.js"></script>

<style src="./User_Mgmt.css"></style>