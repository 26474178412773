<template>
  <div>
    <!--<div class="row">
        <div class="column border-right" style="width:20%;">
          <img :src="profile_image" alt="Trulli" width="250" height="150">
        </div>
        <div class="column" style="font-size:20px; text-align:left;">
          <div>Hello {{ acc_token["name"] }}</div><br>
          <div> ID : {{acc_token["sub"]}}</div>
          <div> Email : {{acc_token["email"]}}</div>
          <div> Email Verified : {{acc_token["email_verified"]}}</div>
        </div>
    </div><hr>-->
    <br>
    <a :href="UI_baseURL + '/refresh_token'">Click Here to Refresh Token </a>
    <!-- <button @click="refresh_token_fn">Refresh Token</button> -->
    <h1>Access-Token</h1>
    <p>{{acc_token_encoded}}</p>
    <h1>ID-Token</h1>
    <p>{{id_token_encoded}}</p>
    <h1>Refresh-Token</h1>
    <p>{{refresh_token_encoded}}</p>
    <h1>Access Token decoded</h1>
    <pre style="text-align:left;">{{ acc_token }}</pre>
    <!-- <pre id="output"></pre> -->
  </div>
</template>

<script>
import jwt_decode from 'jwt-decode';
import axios from 'axios';
import AppConfig from '../../config.js';

export default {
  name: 'User_Profile',
  data(){
    return{
      acc_token: {},
      acc_token_encoded: "",
      KC_baseURL: AppConfig.KC_Base_URL,
      UI_baseURL: AppConfig.UI_Base_URL,
      id_token_encoded: "",
      refresh_token_encoded: "",
      //profile_image: ""
    }
  },
  beforeMount(){
    this.acc_token_encoded = localStorage.getItem('access-token');
    this.acc_token = jwt_decode(this.acc_token_encoded)
    this.id_token_encoded = localStorage.getItem('id-token');
    this.refresh_token_encoded = localStorage.getItem('refresh-token');
    //this.profile_image = this.acc_token["profile_image"]
  },
  methods:{
    refresh_token_fn(){
      let ref_token = localStorage.getItem('refresh-token')
      axios.post(this.KC_baseURL + '/auth/realms/rasreeRealm/protocol/openid-connect/token', {
          grant_type: 'refresh_token',
          client_id : 'adminAppClient',
          //client_secret : 'e8fff681-2117-4705-9c17-2ff8b1553a7a',
          refresh_token : ref_token
        },{
        headers:{
          Authorization: 'Bearer ' + localStorage.getItem('access-token'),
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      }).then(res => {
        console.log("res from axios refresh_token", res);
      });
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
/* Create two equal columns that floats next to each other */
.column {
  float: left;
  /*width: 20%;*/
  padding: 10px;
  height: 25vh;
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}

/* For the border line between columns */
.border-right {
    border-right: 1px solid black;
}
</style>
