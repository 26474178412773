//import user_list from '../assets/users_info';
import axios from 'axios';
import Pagination from './Pagination.vue';
import AppConfig from '../../config.js';

let user_list = []
    export default {
        name: 'User_Mgmt',
        components:{
            Pagination
        },
        data(){
            return{
                api_Base_URL: AppConfig.API_Base_URL,
                user_search: "",
                user_details: {
                    "email" : "user@email.com",
                    "fullname" : "FullName",
                    "user_enabled" : false,
                    "email_verified" : false,
                    "password" : ""
                },
                Searched_User_List: [],
                user_updated: "",
                isClicked: {},
                user_is_searched: false,
                //nextId: 13,
                currentPage: 0,
                pageSize: 3,
                visibleList: [],
                query_limit_msg : false,
                query_resp : "Too many results. \n Please use better Search Expression",
                get_acc_token: "",
                get_id_token: "",
                get_refresh_token: "",
            }
        },
        methods:{
            updatePage(pageNumber) {
                this.currentPage = pageNumber;
                this.updateVisibleTodos();
            },
            updateVisibleTodos() {
                this.visibleList = this.Searched_User_List.slice(this.currentPage * this.pageSize, (this.currentPage * this.pageSize) + this.pageSize);

                // if we have 0 visible todos, go back a page
                if (this.visibleList.length == 0 && this.currentPage > 0) {
                    this.updatePage(this.currentPage -1);
                }
            },
            myFunction() {
                var popup = document.getElementById("myPopup");
                popup.classList.toggle("show");
            },
            async Get_User_Details(user_to_find){
                //x.style.background = 'red';
                this.user_is_searched = true
                this.isClicked = {}
                this.isClicked[user_to_find] = true
                console.log("user deatils : ", user_to_find);
                if(localStorage.getItem('access-token') != null){
                    this.get_acc_token = localStorage.getItem('access-token')
                    this.get_id_token = localStorage.getItem('id-token')
                    this.get_refresh_token = localStorage.getItem('refresh-token')
                }
                this.user_updated = ""
                let res = await axios.get(this.api_Base_URL + '/v1/me/user-details/' + user_to_find,{
                    headers:{
                        Authorization: 'Bearer ' + this.get_acc_token,
                        "id-token": this.get_id_token,
                        "access-token": this.get_acc_token,
                        "refresh-token": this.get_refresh_token,
                    }
                })
                if(res.headers["status_code"] === "200"){
                    let user_data = res.data["resp"]
                    console.log("user_data is : ", user_data)
                    this.user_details["email"] = user_data["email"]
                    this.user_details["fullname"] = user_data["fullname"]
                    this.user_details["user_enabled"] = user_data["user_enabled"]
                    this.user_details["email_verified"] = user_data["email_verified"]
                    //console.log("user_details before", this.user_details)
                    //console.log("user_data", user_data)
                    //user disabled or not
                    var isChecked=document.getElementById("user_enabled").checked;
                    if(isChecked){
                        if(!this.user_details["user_enabled"]){
                            document.getElementById("user_enabled").click()
                        }
                    }
                    else{
                        if(this.user_details["user_enabled"]){
                            document.getElementById("user_enabled").click()
                        }
                    }
                    var isChecked_email=document.getElementById("email_verified").checked;
                    if(isChecked_email){
                        if(!this.user_details["email_verified"]){
                            document.getElementById("email_verified").click()
                        }
                    }
                    else{
                        if(this.user_details["email_verified"]){
                            document.getElementById("email_verified").click()
                        }
                    }
                }
            },
            async Search_User(){
                this.user_is_searched = false
                this.isClicked = {}
                if(localStorage.getItem('access-token') != null){
                    this.get_acc_token = localStorage.getItem('access-token')
                    this.get_id_token = localStorage.getItem('id-token')
                    this.get_refresh_token = localStorage.getItem('refresh-token')
                }
                //call axios
                let res = await axios.get(this.api_Base_URL + '/v1/me/search_user',{
                    headers:{
                        Authorization: 'Bearer ' + this.get_acc_token,
                        "id-token": this.get_id_token,
                        "access-token": this.get_acc_token,
                        "refresh-token": this.get_refresh_token,
                    },
                    params:{
                        search : this.user_search
                    }
                })
                console.log("search user response : ", res)
                if(res.headers["status_code"] === "200"){
                    user_list=[]
                    user_list = res.data["resp"]["users"];
                    if(user_list.length > 9){
                        user_list = []
                        this.query_limit_msg = true
                    }
                    else{
                        this.query_limit_msg = false
                        console.log(user_list);
                        this.Searched_User_List = []
                        this.Searched_User_List = user_list;
                        this.updateVisibleTodos();
                        console.log("searched user List : ", this.Searched_User_List)
                    }
                }
                else if(res.headers["status_code"] === "429"){
                    this.query_limit_msg = true
                    this.Searched_User_List = []
                    this.query_resp = res.data["message"]
                }
                else{
                    //for other things
                }
            },
            user_enabled_fn(){
                var isChecked=document.getElementById("user_enabled").checked;
                console.log("user enabled switch toggled", isChecked);
                this.user_details["user_enabled"] = isChecked
            },
            email_verified_fn(){
                var isChecked=document.getElementById("email_verified").checked;
                console.log("email verified switch toggled", isChecked);
                this.user_details["email_verified"] = isChecked
            },
            async update_details(){
                this.user_updated = ""
                let user_data = {}
                if(localStorage.getItem('access-token') != null){
                    this.get_acc_token = localStorage.getItem('access-token')
                    this.get_id_token = localStorage.getItem('id-token')
                    this.get_refresh_token = localStorage.getItem('refresh-token')
                }
                user_data["username"] = this.user_details["email"]
                user_data["user_enabled"] = this.user_details["user_enabled"]
                user_data["email_verified"] = this.user_details["email_verified"]
                user_data["fullname"] = this.user_details["fullname"]
                let password_list = []
                password_list[0]={"value" : this.user_details["password"] , "type" : "password"}
                user_data["password"] = password_list
                console.log("update user's info, user_data to sent:", user_data)
                let res = await axios.post(this.api_Base_URL + '/v1/me/update-user-details',user_data,{
                    headers:{
                        Authorization: 'Bearer ' + this.get_acc_token,
                        "id-token": this.get_id_token,
                        "access-token": this.get_acc_token,
                        "refresh-token": this.get_refresh_token,
                    },
                })
                console.log("update response : ", res.data)
                this.user_updated = res.data["message"]
                if(res.headers["status_code"] === "200"){
                    this.user_details["email"] = "user@email.com"
                    this.user_details["fullname"] = "FullName"
                    this.user_details["user_enabled"] = false
                    this.user_details["email_verified"] = false
                    this.user_details["password"] = ""
                }
            },
          Password_toggle(){
            var x = document.getElementById("credentials");
            if (x.type === "password") {
              x.type = "text";
            } else {
              x.type = "password";
            }
          }
        }
    }