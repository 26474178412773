<template>
<!-- <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/css/bootstrap.min.css"> -->
    <div style="cursor: pointer;">
        <div 
            @click="nodeClicked"
            :style="{'margin-left': `${depth * 20}px`,
            'color': ((color_node[node.group_id] || color_node[node.user_id]) ? 'red' : 'black')
            }"
            class="node"
            v-bind:id='node.path'
        >
        <span 
            v-if="hasSubGroup"
            class="type"
        >
            {{expanded ? '&#9660;' : '&#9658;'}} {{node.group_name}}
            <i v-if="node.group_name=='Admin'" class="fa fa-fw fa-user" style="color:blue;"></i>
            <i v-else-if="node.group_name=='Auditor'" class="fa fa-fw fa-user" style="color:purple;"></i>
            <i v-else-if="node.group_name=='Tutor'" class="fa fa-fw fa-user" style="color:black;"></i>
            <i v-else-if="node.group_name=='Protege'" class="fa fa-fw fa-user" style="color:green;"></i>
        </span>
        <span v-else>
            &#9671; {{node.username}}
        </span>
        </div>
        <!-- <div>+</div> -->
        <div v-if="expanded">
            <TreeView 
                v-for="subgroup in node.subgroups"
                :key="subgroup.group_name"
                :node="subgroup"
                :depth="depth + 1"
                :color_node="color_node"
                @onClick="(node) => $emit('onClick', node)"
            />
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import AppConfig from '../../config.js';
    export default {
        name: 'TreeView',
        props:{
            node: Object,
            depth:{
                type: Number,
                default: 0,
            },
            color_node: Object
        },
        data(){
            return{
                api_Base_URL: AppConfig.API_Base_URL,
                expanded: false,
                get_acc_token: "",
                get_id_token: "",
                get_refresh_token: "",
            }
        },
        computed:{
            hasSubGroup() {
                return this.node.subgroups;
            }
        },
        methods:{
            async nodeClicked(){
                //console.log("clicked node", this.node)
                if('subgroups' in this.node){
                    if(localStorage.getItem('access-token') != null){
                        this.get_acc_token = localStorage.getItem('access-token')
                        this.get_id_token = localStorage.getItem('id-token')
                        this.get_refresh_token = localStorage.getItem('refresh-token')
                    }
                    let response = await axios.get(this.api_Base_URL + '/v1/me/sub-acad-tree-lazy/' + this.node.group_id,{
                        headers:{
                            Authorization: 'Bearer ' + this.get_acc_token,
                            "id-token": this.get_id_token,
                            "access-token": this.get_acc_token,
                            "refresh-token": this.get_refresh_token,
                        }
                    })
                    console.log("node clicked response", response.data);
                    //this.node.subgroups = response.data["resp"]["node"]
                    //console.log("list to object", Object.assign({}, response.data["resp"]["node"]))
                    //this.node.subgroups = Object.assign({}, response.data["resp"]["node"])
                    //console.log("list to object", typeof response.data["resp"]["node"])
                    //this.node.subgroups =  response.data["resp"]["node"]
                    //let strSubgroups = JSON.stringify(response.data["resp"]["node"])
                    //console.log("stringify data", strSubgroups)
                    Object.assign(this.node.subgroups, response.data["resp"]["node"])
                    //this.node.subgroups = JSON.parse(strSubgroups)
                    //console.log("node value", typeof this.node.subgroups);
                }
                this.expanded = !this.expanded
                this.$emit('onClick', this.node);
            }
        }
    }
</script>

<style scoped>

.node {
    text-align: left;
    font-size: 20px;
}

</style>