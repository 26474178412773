//import Org_data from '../assets/org_info';
import axios from 'axios';
import TreeView from './TreeView.vue';
import jwt_decode from 'jwt-decode';
import AppConfig from '../../config.js';

    export default {
        name: 'Org_Mgmt',
        components:{
          TreeView
        },
        data(){
          return{
            api_Base_URL: AppConfig.API_Base_URL,
            Org_data: {},
            to_crud: true,
            opr_perform: "",
            add_mem: false,
            have_subgrps: true,
            User_Name: "",
            User_id: "",
            First_Name: "",
            line_of_work: "",
            LastName: "",
            Pgroup_name: "",
            Pgroup_id: "",
            Pgroup_path: "",
            clickedIsAGrp: false,
            check_user_exist: "",
            new_grp: "",
            new_grp_grade: "",
            new_grp_language: "",
            new_grp_subject: "",
            new_email: "",
            new_username: "",
            new_firstname: "",
            new_lastname: "",
            new_credentials: "",
            Searched_User_List: [],
            resp_msg: "",
            color_node: {},
            get_acc_token: "",
            get_id_token: "",
            get_refresh_token: "",
            rerenderTreeView: false,
            show_node_details: false,
            show_opr_options: false,
          }
        },
        beforeMount(){
          if(localStorage.getItem('access-token') != null){
            this.get_acc_token = localStorage.getItem('access-token')
            this.get_id_token = localStorage.getItem('id-token')
            this.get_refresh_token = localStorage.getItem('refresh-token')
          }
          let user_name=(jwt_decode(this.get_acc_token))["email"]
          console.log("username/email : ", user_name)
          let request_url = this.api_Base_URL + '/v1/me/acad-tree-lazy'
          //console.log("Base URL", request_url)
          axios.get(request_url,{
            headers:{
              Authorization: 'Bearer ' + this.get_acc_token,
              "id-token": this.get_id_token,
              "access-token": this.get_acc_token,
              "refresh-token": this.get_refresh_token,
            }
          }).then(response => {
            console.log("before mount in org_mgmt", response);
            //output(response.data);
            if(response.headers["status_code"] === "200"){
              this.Org_data = response.data["resp"]["node"]
              //console.log("name here: ",this.Org_data[0]["name"]);
              this.Pgroup_name = this.Org_data[0]["group_name"];
              this.Pgroup_path = this.Org_data[0]["path"];
              this.Pgroup_id = this.Org_data[0]["group_id"];
              this.color_node[this.Org_data[0]["group_id"]] = true
              this.clickedIsAGrp = true
            }
        });
        },
        methods:{
          async refresh_org(){
            this.new_grp = ""
            this.new_grp_grade=""
            this.new_grp_language=""
            this.new_grp_subject=""
            this.new_email = ""
            this.new_username = ""
            this.Searched_User_List = []
            this.new_firstname = ""
            this.new_lastname = ""
            this.new_credentials = ""
            this.resp_msg = ""
            if(localStorage.getItem('access-token') != null){
              this.get_acc_token = localStorage.getItem('access-token')
              this.get_id_token = localStorage.getItem('id-token')
              this.get_refresh_token = localStorage.getItem('refresh-token')
            }
            this.resp_msg = ""
            let response = await axios.get(this.api_Base_URL + '/v1/me/acad-tree-lazy',{
              headers:{
                Authorization: 'Bearer ' + this.get_acc_token,
                "id-token": this.get_id_token,
                "access-token": this.get_acc_token,
                "refresh-token": this.get_refresh_token,
              }
            })
            console.log("response of refresh org", response);
            if(response.headers["status_code"] === "200"){
              this.Org_data = response.data["resp"]["node"]
              this.Pgroup_name = this.Org_data[0]["group_name"];
              this.Pgroup_path = this.Org_data[0]["path"];
              this.Pgroup_id = this.Org_data[0]["group_id"];
              this.rerenderTreeView = !this.rerenderTreeView
              this.color_node = {}
              this.color_node[this.Org_data[0]["group_id"]] = true
              this.clickedIsAGrp = true
              this.show_opr_options = false
            }
            this.resp_msg = response.data["message"]
            this.clear_crud_fn()
          },
          async Search_User(){
            this.resp_msg = ""
            this.Searched_User_List = []
            let res = await axios.get(this.api_Base_URL + '/v1/me/search_user',{
                headers:{
                    Authorization: 'Bearer ' + localStorage.getItem('access-token'),
                    "id-token": localStorage.getItem('id-token'),
                    "access-token": localStorage.getItem('access-token'),
                    "refresh-token": localStorage.getItem('refresh-token'),
                },
                params:{
                  search : this.new_username
                }
            })
            console.log("search user response : ", res)
            if(res.headers["status_code"] === "200"){
              let user_list=[]
              user_list = res.data["resp"]["users"];
              if(user_list.length > 9){
                  user_list = []
                  this.query_limit_msg = true
              }
              else{
                  this.query_limit_msg = false
                  console.log(user_list);
                  this.Searched_User_List = []
                  this.Searched_User_List = user_list;
                  //this.updateVisibleTodos();
                  console.log("searched user List : ", this.Searched_User_List)
              }
            }
            else if(res.headers["status_code"] === "429"){
              this.query_limit_msg = true
              this.Searched_User_List = []
              this.Searched_User_List.push(res.data["message"])
            }
            else{
                //for other things
            }
          },
          nodeWasClicked(node){
            this.new_grp = ""
            this.new_grp_grade=""
            this.new_grp_language=""
            this.new_grp_subject=""
            this.new_email = ""
            this.new_username = ""
            this.Searched_User_List = []
            this.new_firstname = ""
            this.new_lastname = ""
            this.new_credentials = ""
            this.resp_msg = ""
            this.show_node_details = true
            this.color_node = {}
            //this.show_opr_options = true
            //alert(node.name);
            this.have_subgrps = true
            if('subgroups' in node){
              this.color_node[node.group_id] = true
              this.to_crud = true
              this.Pgroup_name = node.group_name
              this.Pgroup_path = node.path;
              this.Pgroup_id = node.group_id
              if(node.group_name ==="Admin" || node.group_name ==="Protege" || node.group_name ==="Tutor" || node.group_name ==="Auditor"){
                this.have_subgrps = false
              }
              this.clickedIsAGrp = true
            }
            else{
              this.color_node[node.user_id] = true
              this.to_crud = false
              this.User_Name = node.username;
              this.User_id = node.user_id;
              this.First_Name = node.firstName
              this.LastName = node.lastName
              this.line_of_work = node.parentName
              this.Pgroup_name = node.parentName
              this.Pgroup_path = node.parentPath
              this.Pgroup_id = node.parentID
              this.clickedIsAGrp = false
            }
            console.log("node : ", node)
            //console.log("key is there or not: ", 'SubGroup' in node)
          },
          crud_fn(){
            this.new_grp = ""
            this.new_grp_grade=""
            this.new_grp_language=""
            this.new_grp_subject=""
            this.new_email = ""
            this.new_username = ""
            this.Searched_User_List = []
            this.new_firstname = ""
            this.new_lastname = ""
            this.new_credentials = ""
            this.resp_msg = ""
            this.show_opr_options = true
            let ele = document.getElementsByName('crud_opr');
            //console.log("radio box values are: ", ele)
            for(let i = 0; i < ele.length; i++) {
                if(ele[i].checked){
                  //console.log("selected is: ", ele[i].id)
                  if(ele[i].id === "add_opr"){
                    this.opr_perform = "Add Operation"
                  }
                  else{
                    this.opr_perform = "Remove Group Operation"
                  }
                }
            }
          },
          clear_crud_fn(){
            // clear radio buttons
            var ele = document.getElementsByName("crud_opr");
            for(var i=0;i<ele.length;i++){
                ele[i].checked = false;
            }
          },
          check_new_user(){
            this.new_grp = ""
            this.new_grp_grade=""
            this.new_grp_language=""
            this.new_grp_subject=""
            this.new_email = ""
            this.new_username = ""
            this.Searched_User_List = []
            this.new_firstname = ""
            this.new_lastname = ""
            this.new_credentials = ""
            this.resp_msg = ""
            let ele = document.getElementsByName('new_user_type');
            for(let i = 0; i < ele.length; i++) {
              if(ele[i].checked){
                if(ele[i].id === "new_user_add"){
                  this.check_user_exist = "not exist";
                }
                else{
                  this.check_user_exist = "exist";
                }
              }
            }
          },
          async add_existed_user(){
            this.resp_msg = ""
            if(localStorage.getItem('access-token') != null){
              this.get_acc_token = localStorage.getItem('access-token')
              this.get_id_token = localStorage.getItem('id-token')
              this.get_refresh_token = localStorage.getItem('refresh-token')
            }
            console.log("user to add : ", this.new_username)
            console.log("in group to add : ", this.Pgroup_path)
            let response = await axios.post(this.api_Base_URL + '/v1/me/add-existing-user-to-pseudo-node/' + this.Pgroup_id,{
                username : this.new_username
              },{
              headers:{
                Authorization: 'Bearer ' + this.get_acc_token,
                "id-token": this.get_id_token,
                "access-token": this.get_acc_token,
                "refresh-token": this.get_refresh_token,
              },
            })
            console.log("response of add existing user", response);
            if(response.headers["status_code"] === "200"){
              this.Org_data = response.data["resp"]["org-tree"]["node"]
              this.Pgroup_name = this.Org_data[0]["group_name"];
              this.Pgroup_path = this.Org_data[0]["path"];
              this.Pgroup_id = this.Org_data[0]["group_id"];
              this.color_node = {}
              this.color_node[this.Org_data[0]["group_id"]] = true
              this.clickedIsAGrp = true
            }
            this.resp_msg = response.data["message"]
            this.show_node_details = false
            this.show_opr_options = false
            this.clear_crud_fn()
            //this.clickedIsAGrp = false
          },
          async add_new_user(){
            if(localStorage.getItem('access-token') != null){
              this.get_acc_token = localStorage.getItem('access-token')
              this.get_id_token = localStorage.getItem('id-token')
              this.get_refresh_token = localStorage.getItem('refresh-token')
            }
            let data ={}
            //data["group_id"] = this.Pgroup_id
            data["email"] = this.new_email
            data["username"] = this.new_username
            data["firstName"] = this.new_firstname
            data["lastName"] = this.new_lastname
            data["credentials"] = []
            let credentials_dict = {}
            credentials_dict["value"] = this.new_credentials
            credentials_dict["type"] = "password"
            data["credentials"].push(credentials_dict)
            console.log(data);
            console.log("new user in group: ", this.Pgroup_name);
            let response = await axios.post(this.api_Base_URL + '/v1/me/add-new-user-to-pseudo-node/' + this.Pgroup_id,data,{
              headers:{
                Authorization: 'Bearer ' + this.get_acc_token,
                "id-token": this.get_id_token,
                "access-token": this.get_acc_token,
                "refresh-token": this.get_refresh_token,
              },
            })
            console.log("response of add new user", response);
            this.resp_msg = response.data["message"]
            if(response.headers["status_code"] === "200"){
              this.Org_data = response.data["resp"]["org-tree"]["node"]
              this.Pgroup_name = this.Org_data[0]["group_name"];
              this.Pgroup_path = this.Org_data[0]["path"];
              this.Pgroup_id = this.Org_data[0]["group_id"];
              this.color_node = {}
              this.color_node[this.Org_data[0]["group_id"]] = true
              this.clickedIsAGrp = true
            }
            this.show_node_details = false
            this.show_opr_options = false
            this.clear_crud_fn()
            //this.clickedIsAGrp = false
          },
          async add_new_grp(){
            if(localStorage.getItem('access-token') != null){
              this.get_acc_token = localStorage.getItem('access-token')
              this.get_id_token = localStorage.getItem('id-token')
              this.get_refresh_token = localStorage.getItem('refresh-token')
            }
            this.resp_msg = ""
            console.log("Parent group is: ", this.Pgroup_name)
            console.log("Group name is: ", this.new_grp)
            let req_url_addgrp = this.api_Base_URL + '/v1/me/add-node/' + this.Pgroup_id
            console.log("add new grp req url is : ", req_url_addgrp)
            let response = await axios.post(req_url_addgrp,{
                new_node_name : this.new_grp,//change
              },{
                headers:{
                  Authorization: 'Bearer ' + this.get_acc_token,
                  "id-token": this.get_id_token,
                  "access-token": this.get_acc_token,
                  "refresh-token": this.get_refresh_token,
                },
              });
              console.log("Add new grp res : ",response);
              if(response.headers["status_code"] === "200"){
                this.Org_data = response.data["resp"]["org-tree"]["node"]
                this.Pgroup_name = this.Org_data[0]["group_name"];
                this.Pgroup_path = this.Org_data[0]["path"];
                this.Pgroup_id = this.Org_data[0]["group_id"];
                this.color_node = {}
                this.color_node[this.Org_data[0]["group_id"]] = true
                this.clickedIsAGrp = true
              }
              this.resp_msg = response.data["message"]
              this.show_node_details = false
              this.show_opr_options = false
              this.clear_crud_fn();
              //this.clickedIsAGrp = false
              return this.resp_msg
          },
          Password_toggle(){
            var x = document.getElementById("credentials");
            if (x.type === "password") {
              x.type = "text";
            } else {
              x.type = "password";
            }
          },
          async remove_grp(){
            this.resp_msg = ""
            console.log("Group to remove is: ",this.Pgroup_name);
            console.log("Group id to remove is: ",this.Pgroup_id);
            var result = confirm("Want to Remove Group ' " + this.Pgroup_name + this.Pgroup_id)
            if(result){
              if(localStorage.getItem('access-token') != null){
                this.get_acc_token = localStorage.getItem('access-token')
                this.get_id_token = localStorage.getItem('id-token')
                this.get_refresh_token = localStorage.getItem('refresh-token')
                console.log("---1---")
              }
              let response = await axios.delete(this.api_Base_URL + '/v1/me/del-node/' + this.Pgroup_id,{
                  headers:{
                    Authorization: 'Bearer ' + this.get_acc_token,
                    "id-token": this.get_id_token,
                    "access-token": this.get_acc_token,
                    "refresh-token": this.get_refresh_token,
                  }
                })
              
              console.log("remove group res : ", response);
              if(response.headers["status_code"] === "200"){
                this.Org_data = response.data["resp"]["org-tree"]["node"]
                this.Pgroup_name = this.Org_data[0]["group_name"];
                this.Pgroup_path = this.Org_data[0]["path"];
                this.Pgroup_id = this.Org_data[0]["group_id"];
                this.color_node = {}
                this.color_node[this.Org_data[0]["group_id"]] = true
                this.clickedIsAGrp = true
              }
              this.resp_msg = response.data["message"]
              this.show_opr_options = false
              this.clear_crud_fn()
              //this.clickedIsAGrp = false
            }
          },
          async remove_user(remove_email, remove_user_id){
            this.resp_msg = ""
            console.log("user to remove is: ", remove_email);
            console.log("And from group: ", this.Pgroup_id)
            var result = confirm("Want to Remove User ' " + remove_email + "' ?")
            if(result){
              if(localStorage.getItem('access-token') != null){
                this.get_acc_token = localStorage.getItem('access-token')
                this.get_id_token = localStorage.getItem('id-token')
                this.get_refresh_token = localStorage.getItem('refresh-token')
              }
              let response = await axios.delete(this.api_Base_URL + '/v1/me/remove-user/' + remove_user_id,{
                headers:{
                  Authorization: 'Bearer ' + this.get_acc_token,
                  "id-token": this.get_id_token,
                  "access-token": this.get_acc_token,
                  "refresh-token": this.get_refresh_token,
                },
                params:{
                  node_id: this.Pgroup_id
                }
              })
              console.log("remove user response", response);
              if(response.headers["status_code"] === "200"){
                this.Org_data = response.data["resp"]["org-tree"]["node"]
                this.Pgroup_name = this.Org_data[0]["group_name"];
                this.Pgroup_path = this.Org_data[0]["path"];
                this.Pgroup_id = this.Org_data[0]["group_id"];
                this.color_node = {}
                this.color_node[this.Org_data[0]["group_id"]] = true
                this.clickedIsAGrp = true
              }
              this.resp_msg = response.data["message"]
              this.show_opr_options = false
              this.to_crud = true
              this.clear_crud_fn()
            }
          }
        }
    }