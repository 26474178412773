<template>
<div>
<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">
  <div v-show="isAuth" class="topnav">
    <a :href="UI_baseURL">Home</a>
    <div v-if="is_admin">
      <a :href="UI_baseURL + '/Org_Mgmt'">Org Management</a>
      <a :href="UI_baseURL + '/User_Mgmt'">User Management</a>
    </div>
    <div style="float:right;">
      <!-- <a href="http://localhost:8888/logout">Logout</a> -->
      <div class="dropdown">
        <button class="dropbtn" style="cursor: pointer;">{{ UserName }}
          <i class="fa fa-fw fa-user"></i>
        </button>
        <div class="dropdown-content">
          <a :href="UI_baseURL + '/user_profile'">Profile</a>
          <a :href="UI_baseURL + '/logout'">Logout</a>
        </div>
      </div>
    </div>
  </div>
  <router-view />
</div>
</template>

<script>
import jwt_decode from 'jwt-decode';
import AppConfig from '../config.js';
import axios from 'axios';

export default {
  name: 'App',
  data(){
    return{
      isAuth: false,
      UserName: "User",
      is_admin: false,
      UI_baseURL: AppConfig.UI_Base_URL,
      api_Base_URL: AppConfig.API_Base_URL,
      get_acc_token: "",
      get_id_token: "",
      get_refresh_token: "",
    }
  },
  beforeMount(){
    if(localStorage.getItem('access-token') != null){
        this.get_acc_token = localStorage.getItem('access-token')
        this.get_id_token = localStorage.getItem('id-token')
        this.get_refresh_token = localStorage.getItem('refresh-token')
        let tokenParsed = jwt_decode(this.get_acc_token);
        console.log(tokenParsed);
        this.UserName = tokenParsed["email"]
    }
    let request_url = this.api_Base_URL + '/v1/me/admin-home'
    console.log("Base URL", request_url)
    axios.get(request_url,{
      headers:{
          Authorization: 'Bearer ' + this.get_acc_token,
          "id-token": this.get_id_token,
          "access-token": this.get_acc_token,
          "refresh-token": this.get_refresh_token,
      }
      }).then(response => {
          console.log("app.vue resp of admin-home", response);
          /*if(response.headers["status_code"] === "200"){
              this.isAuth = true
              this.is_admin = true
          }*/
          this.isAuth = true
          this.is_admin = true
      }).catch(err => {
        console.log("error in admin-home in app vue file is", err)
        this.is_admin = false
        let request_url_userme = this.api_Base_URL + '/v1/me'
        axios.get(request_url_userme,{
          headers:{
              Authorization: 'Bearer ' + this.get_acc_token,
              "id-token": this.get_id_token,
              "access-token": this.get_acc_token,
              "refresh-token": this.get_refresh_token,
          }
          }).then(response => {
            console.log("userme", response.data);
            /*if(response.headers["status_code"] === "200"){
                this.isAuth = true
            }
            else{
                this.isAuth = false
            }*/
            this.isAuth = true
          }).catch(err => {
            console.log("error in v1/me in app vue is", err)
            this.isAuth = false
          });
      });
    /*if(localStorage.getItem('access-token')){
      this.isAuth = true;
      let acc_token = localStorage.getItem('access-token')
      let tokenParsed = jwt_decode(acc_token);
      console.log(tokenParsed);
      this.UserName = tokenParsed["email"]
      // for is_admin
      let roles = tokenParsed["realm_access"]["roles"]
      let list_len = roles.length;
      for(let i=0;i<list_len;i++){
        if(roles[i]==="batchAdmin" || roles[i]==="orgAdminRole" || roles[i]==="superAdmin"){
          this.is_admin = true
          break
        }
      }
    }*/
  }
}
</script>

<style>
@import './main.css';
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 0px;
}
</style>
