import * as Keycloak from 'keycloak-js';
import AppConfig from '../config.js';

let initOptions = {
    url: (AppConfig.KC_Base_URL + '/auth'),
    realm: 'rasreeRealm',
    clientId: 'adminAppClient',
}
//onLoad: 'login-required'

let keycloak = Keycloak(initOptions);

export default keycloak