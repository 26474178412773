<template>
  <div>
    <h1 id="ga_id" style="color:blue;">This is the area for general Announcement And Any analytic for Admin</h1>
    <h2 style="color: #dd9940;">For now just a placeholder</h2><br><br>
    <!--<h1 style="color:red;" v-if="!is_admin">You do not have admin access <br>
    Contact kcrasree@admin.com
    </h1>-->
    <h2 id="general_news_section_id" style="color:blue;">{{admin_general_news}}</h2>
  </div>
</template>

<script>
import AppConfig from '../../config.js';
import axios from 'axios';

export default {
  name: 'SignedHome',
  data(){
    return{
      api_Base_URL: AppConfig.API_Base_URL,
      is_admin: false,
      get_acc_token: "",
      get_id_token: "",
      get_refresh_token: "",
      admin_general_news: "",
    }
  },
  beforeMount(){
    if(localStorage.getItem('access-token') != null){
      this.get_acc_token = localStorage.getItem('access-token')
      this.get_id_token = localStorage.getItem('id-token')
      this.get_refresh_token = localStorage.getItem('refresh-token')
    }
    let request_url = this.api_Base_URL + '/v1/me/admin-home'
    //console.log("Base URL", request_url)
    axios.get(request_url,{
      headers:{
        Authorization: 'Bearer ' + this.get_acc_token,
        "id-token": this.get_id_token,
        "access-token": this.get_acc_token,
        "refresh-token": this.get_refresh_token,
      }
    }).then(response => {
        console.log(response.data);
        if(response.headers["status_code"] === "200 OK"){
          this.admin_general_news = response.data["resp"]["general news"]
        }
        else{
          this.admin_general_news = response.data["resp"]["message"]
        }
    });
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
b {
  color: #1c62b3;
}
</style>
